<template>
  <div class="change-directive-item-index">
    <my-search-bar>
      <el-form
        :inline="true"
        >
        <el-form-item>
          <el-button>查询</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      v-if="changeDirectiveItemList.length"
      :data="changeDirectiveItemList"
      header-cell-class-name="bg-info text-light"
      :height="tableHeight"
      border>
      <el-table-column width="50" header-align="center" align="center" label="序号" type="index"></el-table-column>
      <el-table-column width="180" header-align="center" align="center" label="子目号" prop="meterageCode"></el-table-column>
      <el-table-column width="180" header-align="center" align="center" label="原始子目号" prop="meterageDisplayCode"></el-table-column>
      <el-table-column header-align="center" align="center" label="子目名称" prop="meterageName"></el-table-column>
      <el-table-column width="120" header-align="center" align="center" label="章节" prop="chapter"></el-table-column>
      <el-table-column width="150" header-align="center" align="center" label="单位" prop="unit"></el-table-column>
      <el-table-column width="150" header-align="center" align="center" label="单价" prop="price"></el-table-column>
      <el-table-column width="150" header-align="center" align="center" label="数量" prop="num"></el-table-column>
      <el-table-column width="150" header-align="center" align="center" label="金额" prop="amount"></el-table-column>
      <el-table-column width="150" header-align="center" align="center" label="部位" prop="part"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import changeDirectiveItemService from '@/services/changeDirectiveItemService'
import PageInfo from '@/support/PageInfo'
import PageOperation from '@/mixins/PageOperation'
import ChangeDirectiveItemModel from '@/model/ChangeDirectiveItemModel'

export default {
  name: 'ChangeDirectiveItemIndex',
  mixins: [
    PageOperation
  ],
  components: {
    MySearchBar
  },
  data () {
    return {
      changeDirectiveGuid: this.$route.query.changeDirectiveGuid,
      changeDirectiveItemList: []
    }
  },
  methods: {
    handleAdd () {
      this.$router.push(`/changeDirectiveItemDetail?changeDirectiveGuid=${this.changeDirectiveGuid}`)
    },
    handleSync () {
      this.$confirm('确认要现在同步吗？')
        .then(() => {
          changeDirectiveItemService.sync(this.changeDirectiveGuid)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '同步成功！'
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '操作失败，请重试！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消同步！'
          })
        })
    },
    handleDelete (row) {
      this.changeDirectiveItemModel = new ChangeDirectiveItemModel(row)
      this.$confirm(`您确定要删除${this.changeDirectiveItemModel.meterageDisplayCode}吗？`)
        .then(() => {
          changeDirectiveItemService.delete(this.changeDirectiveItemModel.changeDirectiveItemGuid)
            .then(res => {
              if (res.data.code === 1) {
                this.getData()
                this.$message({
                  type: 'success',
                  message: '操作成功！'
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '操作失败，请重试！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    getData () {
      changeDirectiveItemService.list(new PageInfo({ pageIndex: 1, pageSize: 10 }), { changeDirectiveGuid: this.changeDirectiveGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.changeDirectiveItemList = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载列表失败，请重试！'
          })
        })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
</style>
